.styled-table {
  border-collapse: collapse;
  margin: 5px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  text-align: center;
}

.styled-table th,
.styled-table td {
  padding: 10px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
